<template>
  <div class="w-full h-full pt-5">
    <div class="flex mb-10 px-5">
      <h1 class="text-xl text-left font-extrabold mr-4">Back Pay</h1>
      <breadcrumb :items="breadcrumbs" />
    </div>
    <div class="px-3">
      <card class="p-5 mt-3">
        <div class="mx-2 mt-6">
          <Tab
            :tabs="tabs"
            border
            :active-tab="currentTab"
            @currentTab="currentTab = $event"
            v-model="currentTab"
          />
          <div>
            <div>
              <Card class="mt-6 p-5">
                <div :class="'flex'" v-if="currentTab === 'Drafts'">
                  <div class="flex flex-grow" />
                  <div class="flex mb-6" style="justify-content: flex-end">
                    <Button
                      :disabled="annualGrossTotal !== 100"
                      class="text-white"
                      :background="appearance.buttonColor || defaultBTNColor"
                      @click="openModal = true"
                    >
                      New Pay Run
                    </Button>
                    <Button
                      class="bg-white ml-4 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                      style-name="width: 180px"
                      options
                      :width="`auto`"
                      :disabled="annualGrossTotal !== 100"
                      :option-list=" environment === 'production' || environment === 'staging' ? ['Regular Pay Run'] : ['Terminal Pay', 'Regular Pay Run']"
                      @click="buttonOptionSelected($event)"
                    >
                      Pay Options
                    </Button>
                  </div>
                </div>
                <template v-slot:footer>
                  <card-footer v-if="!showError" :show-bulk-actions="false" />
                </template>
              </Card>
            </div>
          </div>

          <sTable
            :headers="backPayHeaders"
            :items="selectedPayrunType"
            style="width:100%; height:auto"
            class="w-full"
            aria-label="payrun table"
            :loading="loading"
            :has-checkbox="false"
            :has-number="false"
            :pagination-list="metaData"
            @page="handlePage($event)"
            @itemsPerPage="handleItemsPerPage($event)"
            page-sync
            v-if="selectedPayrunType.length > 0 || loading"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.year" style="width: 189px">
                {{
                  `${$DATEFORMAT(
                    new Date(item.data.year, item.data.month - 1, 1),
                    "LLLL"
                  )} ${item.data.year}`
                }}
              </span>
              <div v-else-if="item.payFrequency">
                {{ handlePayFrequency(item.data.payFrequency) }}
              </div>
              <div class="capitalize" v-else-if="item.payType">
                {{ item.data.payType }}
              </div>
              <div class="" v-else-if="item.paySplitPosition">
                {{ `${$getOrdinal(item.data.paySplitPosition)} Payment` }}
              </div>

              <span v-else-if="item.paidDate">
                {{ item.data.paidDate }}
              </span>
              <span v-else-if="item.paidAt">
                {{
                  item.data.paidAt
                    ? $DATEFORMAT(new Date(item.data.paidAt), "yyyy-MM-dd")
                    : "-"
                }}
              </span>
              <span v-else-if="item.createdAt">
                {{
                  item.data.createdAt
                    ? $DATEFORMAT(new Date(item.data.createdAt), "yyyy-MM-dd")
                    : "-"
                }}
              </span>
              <span v-else-if="item.paymentReferrenceId">
                {{ item.data.paymentReferrenceId }}
              </span>
              <span v-else-if="item.bankName">
                {{ toTitleCase(item.data.bankName) }}
              </span>
              <span v-else-if="item.accountNumber">
                {{ item.data.accountNumber }}
              </span>
              <span v-else-if="item.status">
                {{ item.data.status }}
              </span>

              <span v-else-if="item.type">
                {{ toTitleCase(item.data.type) }}
              </span>
              <span
                :class="
                  item.data.paymentChannel === 'in-app'
                    ? 'text-mediumSeaGreen'
                    : 'text-darkPurple'
                "
                v-else-if="item.paymentChannel"
              >
                {{ toTitleCase(item.data.paymentChannel) }}
              </span>
              <span v-else-if="item.payDate">
                {{ item.data.payDate ? item.data.payDate : "-" }}
              </span>
              <span v-else-if="item.grossEarnings">
                {{
                  item.data.grossEarnings
                    ? convertToCurrency(item.data.grossEarnings)
                    : "-"
                }}
              </span>
              <span v-else-if="item.netEarnings">
                {{
                  item.data.netEarnings
                    ? convertToCurrency(item.data.netEarnings)
                    : "-"
                }}
              </span>
              <span v-else-if="item.totalCost" class="grosspay">
                {{
                  item.data.totalCost
                    ? convertToCurrency(item.data.totalCost)
                    : "-"
                }}
              </span>
              <span v-else-if="item.id">
                <div
                  class="cursor-pointer"
                  v-if="!item.data.editable && currentTab !== 'Approved'"
                  @click="viewDetails(item.data)"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
                <Menu
                  v-if="!item.data.editable && currentTab === 'Approved'"
                  left
                  top="-150"
                  margin="24"
                  class="my-2 p-0"
                >
                  <template v-slot:title>
                    <icon icon-name="more_icon" size="xs" />
                  </template>
                  <div style="width: 116px; height: 81px" class="py-3 px-2">
                    <div
                      @click="viewDetails(item.data)"
                      class="flex py-1 px-3 more h-8 cursor-pointer"
                    >
                      <icon
                        icon-name="icon-eye"
                        class-name="text-blueCrayola mr-2"
                        size="xsm"
                      />
                      <p class="pt-1">View</p>
                    </div>
                    <div
                      @click="showCancelComponent(item.data.id)"
                      class="flex py-1 px-3 more h-8 cursor-pointer"
                    >
                      <icon
                        icon-name="close-icon"
                        class-name="text-desire mr-2"
                        size="xsm"
                      />
                      <p class="pt-1">Cancel</p>
                    </div>
                  </div>
                </Menu>
              </span>
            </template>
          </sTable>

          <div
            class="w-full flex flex-col justify-center items-center mt-10"
            v-if="
              selectedPayrunType.length === 0 &&
              !loading
            "
          >
            <icon icon-name="empty_agreement" size="l" style="width: 300px" />
            <div class="w-1/2 text-base text-center">
              There is no Back Pay to process at this time.
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import Button from "@/components/Button";

export default {
  name: "BackPay",
  components: {
    STable,
    Menu,
    Breadcrumb,
    Tab,
    Card,
    Button,
  },
  data() {
    return {
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Off-Cycle",
          id: "Off-Cycle",
        },
        { disabled: false, text: "BackPay", href: "BackPay", id: "BackPay" },
      ],
      currentTab: this.$route.query.currentTab || "Drafts",
      showError: false,
      annualGrossTotal: 100,
      showCancelModal: false,
      payRunDataId: "",
      environment: process.env.NODE_ENV,
      openModal: false,
      metaData: {},
      tabs: ["Drafts", "Under Review", "Approved", "Disapproved", "Paid"],
      currentText: "Created At",
      payruns: {},
      loading: true,
      selectedPayrunType: [],
      status: "",
      state: "drafts",
      optionSelected: "Regular Pay Run",
      payrunItems: [],
      itemsPerPage: null,
      numberOfPage: null,
    };
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    backPayHeaders() {
      let headerArry = [];
      if (this.currentTab === "Drafts") {
        headerArry = [
          { title: "Pay Run Type", value: "payType" },
          { title: "Pay Run Cycle", value: "year" },
          { title: "Due Date", value: "payDate" },
          { title: "Gross Earnings", value: "grossEarnings" },
          { title: "Pension", value: "totalPension" },
          { title: "Net Earnings", value: "netEarnings" },
          { title: "Total Cost", value: "totalCost" },
          { title: "", value: "id", image: true },
        ];
      } else {
        headerArry = [
          { title: "Pay Run Cycle", value: "year" },
          { title: "Pay type", value: "payType" },
          { title: "Pay frequency", value: "payFrequency" },
          { title: "Pay SCHEDULE", value: "paySplitPosition" },
          {
            title: this.currentTab === "Paid" ? "Pay Date" : "Created At",
            value: this.currentTab === "Paid" ? "paidAt" : "createdAt",
          },
          { title: "Gross Earnings", value: "grossEarnings" },
          { title: "Net Earnings", value: "netEarnings" },
          { title: "Total Cost", value: "totalCost" },
          { title: "", value: "id" },
        ];
        if (this.currentTab === "Paid") {
          headerArry.splice(6, 0, {
            title: "Channel",
            value: "paymentChannel",
          });
        }
      }
      return headerArry;
    },
  },

  watch: {
    currentTab(value) {
      if (value === "Drafts") {
        this.loading = true;
        this.$_getPayrun("draft", "&page=1&perPage=50", "backpay").then(
          (result) => {
            this.selectedPayrunType = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          }
        );
      } else if (value === "Under Review") {
        this.loading = true;
        this.$_getPayrun("review", "&page=1&perPage=50", "backpay").then(
          (result) => {
            this.selectedPayrunType = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          }
        );
      } else if (value === "Approved") {
        this.loading = true;
        this.$_getPayrun("approved", "&page=1&perPage=50", "backpay").then(
          (result) => {
            this.selectedPayrunType = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          }
        );
      } else if (value === "Disapproved") {
        this.loading = true;
        this.$_getPayrun("disapproved", "&page=1&perPage=50", "backpay").then(
          (result) => {
            this.selectedPayrunType = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          }
        );
      } else if (value === "Paid") {
        this.loading = true;
        this.$_getPayrun("paid", "&page=1&perPage=50", "backpay").then(
          (result) => {
            this.selectedPayrunType = result.data.payruns;
            this.metaData = result.data.meta;
            this.loading = false;
          }
        );
      }
      this.$router.push({ query: { currentTab: value } });
    },
  },

  methods: {
    async viewDetails(value) {
      if (this.currentTab === "Drafts") {
        const draftPayload = {
          month: value.month,
          year: value.year,
        };
        this.$router.push({
          name: "viewBackpay",
          params: { id: value.id },
          query: draftPayload,
        });
      } else if (this.currentTab === "Under Review") {
        const slug = "reviewSubmittedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Approved") {
        const slug = "viewApprovedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Disapproved") {
        const slug = "viewDisapprovedPayRunDetails";
        this.routeView(value, slug);
      } else if (this.currentTab === "Paid") {
        const slug = "viewPaidPayRunDetails";
        this.routeView(value, slug);
      }
    },

    async routeView(value, slug) {
      try {
        await this.$handlePrivilege("payRun", slug);
        this.$router.push({
          name: "View BackPay Review",
          params: { id: value.id },
          query: { currentTab: this.currentTab },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    getBackPayData() {
      this.loading = true;
      this.$_getBackPay().then((response) => {
        this.payrunItems = response.data.unprocessedPayruns;
        this.loading = false;
      });
    },

    buttonOptionSelected(option) {
      this.optionSelected = option;
      switch (option) {
        case "Terminal Pay":
          this.$router.push({ name: "terminalPay" });
          break;

        case "Regular Pay Run":
          this.$router.push({ name: "Pay Runs" });
          break;

        default:
          this.checkCurrentTab("drafts");
          break;
      }
    },
  },

  mounted() {
    if (this.currentTab === "Drafts") {
      this.$_getPayrun("draft", "&page=1&perPage=50", "backpay").then(
        (result) => {
          this.selectedPayrunType = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Under Review") {
      this.loading = true;
      this.$_getPayrun("review", "&page=1&perPage=50", "backpay").then(
        (result) => {
          this.selectedPayrunType = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Approved") {
      this.loading = true;
      this.$_getPayrun("approved", "&page=1&perPage=50", "backpay").then(
        (result) => {
          this.selectedPayrunType = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Disapproved") {
      this.loading = true;
      this.$_getPayrun("disapproved", "&page=1&perPage=50", "backpay").then(
        (result) => {
          this.selectedPayrunType = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    } else if (this.currentTab === "Paid") {
      this.loading = true;
      this.$_getPayrun("paid", "&page=1&perPage=50", "backpay").then(
        (result) => {
          this.selectedPayrunType = result.data.payruns;
          this.metaData = result.data.meta;
          this.loading = false;
        }
      );
    }
  },
};
</script>
